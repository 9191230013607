import { type ComponentType } from 'react';
import { type MutationFunction, useMutation } from 'react-query';
import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { TripSubJobType } from '../models';
/**
 * POST - /api/v1/eames/projects/:id/order_trips
 */

// HTTP

export interface CreateTripXhrParams {
  subJobType?: TripSubJobType | undefined;
  tripDate?: string;
}

export interface CreateTripXhrVariables {
  projectId: number;
  params?: CreateTripXhrParams;
}

export const createTripXhr = (
  projectId: number,
  params?: CreateTripXhrParams
): Promise<unknown> =>
  http.post<unknown>(`/api/v1/eames/projects/${projectId}/order_trips`, params);

// MutationFn

type CreateTripMutation = MutationFunction<unknown, CreateTripXhrVariables>;

export const createTripMutationFn: CreateTripMutation = ({
  projectId,
  params,
}) => createTripXhr(projectId, params);

// Hook
export const useCreateTripMutation = () => {
  return useMutation(createTripMutationFn);
};

// With Mutation HOC

const withCreateTripMutationPropKey = 'createTripMutation';

export type WithCreateTripMutationProps = WithMutationPropsByMutation<
  typeof withCreateTripMutationPropKey,
  CreateTripMutation
>;

export function withCreateTripMutation<P extends WithCreateTripMutationProps>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateTripMutationProps>> {
  return withMutation(
    withCreateTripMutationPropKey,
    createTripMutationFn
  )(Component as ComponentType<WithCreateTripMutationProps>);
}
